import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConnectionStatus, Network } from '@capacitor/network';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';

export type NextStep = 'dropoff' | 'completion' | '';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  private networkSubject$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public connected: boolean;

  networkStatus: Observable<boolean> = this.networkSubject$.asObservable();

  constructor(
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
  ) {
    this.subscribeToNetworkChanges();
  }

  private subscribeToNetworkChanges(): void {
    Network.addListener(
      'networkStatusChange',
      (status: ConnectionStatus): void => {
        let connected: boolean = status.connected;

        if (this.connected === connected) {
          return;
        }

        let previousState: boolean = this.networkSubject$.value;

        if (previousState !== connected) {
          this.connected = connected;
          this.networkSubject$.next(connected);
          if (previousState === null && connected) {
            /*
              we don't want to display message "movacarpro_warning_connection_restablished"
              when the previous status is unknown and the new status is connected
             */
            return;
          }
          this.propagateStatusChange();
        }
      },
    );
  }

  private propagateStatusChange(): void {
    if (!this.connected) {
      this.toastService.showToast(
        'warning',
        this.translateService.instant(
          'movacarpro_warning_message_offline_transaction',
        ),
        () => {},
        5000,
      );

      return;
    }

    this.toastService.showToast(
      'success',
      this.translateService.instant(
        'movacarpro_warning_connection_restablished',
      ),
      () => {},
      5000,
    );
  }

  public async getNetworkStatus(): Promise<boolean> {
    const connectionStatus: ConnectionStatus = await Network.getStatus();
    this.connected = connectionStatus.connected;
    this.networkSubject$.next(this.connected);

    return this.connected;
  }
}
