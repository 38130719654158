export const environment = {
  production: true,
  environmentName: 'production',
  carmovaApiUrl: 'https://carmova-api-production-2e4g646uda-ew.a.run.app',
  sentryDsn:
    'https://a4fe0e33952c017a218d52d9c9eda335@o1097620.ingest.us.sentry.io/4506982529892353',
  sentryEnabled: true,
  enableServiceWorker: true,
  transactionMinPicture: 4,
  movacarProUrl: 'https://movacarpro.com/',
  vehicleInspectionUrl:
    'https://vehicle-inspection-production-2e4g646uda-ew.a.run.app',
  vehicleInspectionClientKey: 'TQuRMzOqOThTRmjl',
  vehicleInspectionClientSecret: 'AK9uiBb3Qd8Rnfc44EJDmkynogFe7vyG',
  firebaseConfig: {
    apiKey: 'AIzaSyCURAfZOczM0I6b3BjVmEtdqb8jG6RDECA',
    authDomain: 'movacar-pro-86e5c.firebaseapp.com',
    projectId: 'movacar-pro-86e5c',
    storageBucket: 'movacar-pro-86e5c.appspot.com',
    messagingSenderId: '446895935042',
    appId: '1:446895935042:web:122b743a57bd8956e427db',
    measurementId: 'G-007T37338S',
  },
  monkeyPdfConfig: {
    createMonkeyPdfUrl: 'https://api.pdfmonkey.io/api/v1/documents',
    downloadMonkeyPdfUrl: 'https://api.pdfmonkey.io/api/v1/document_cards',
    templateId: 'D5B5B775-CFCE-4727-8CD3-FC1A2FE270D0',
    token: 'WKyNnk4StyxWW3qsNsM2oswGZvzK4y2Q',
  },
  zoho: {
    salesIQ: {
      ios: {
        appKey:
          'nyPRcpxHd4gXznosJGJZ5MBPaaa42QEIBeGeNOwqIzc%2FV%2BBw7jD5uqFM8qYyYmg%2F_eu',
        accessKey:
          'eNeAjMiuSCF9Qg5GRscSNbJl8hu8Pw4MLPVri1Fn%2B3WWDiVRXgLm%2BzpUu08dGZpdxCbQkgjkKpNk42vOb9TBXIAAOeSew%2B7VVFKCVBv1iRJN2kFqlWx1ZCPwVNbg7ejK',
      },
      android: {
        appKey:
          'nyPRcpxHd4gXznosJGJZ5MBPaaa42QEIBeGeNOwqIzc%2FV%2BBw7jD5uqFM8qYyYmg%2F_eu',
        accessKey:
          'eNeAjMiuSCFJvf%2Ftsm3zHh1lgY6TDGBH09QgngWzuEgUqLPpNaIjuDlQ5XPfzMFeCsTqTjZ6oPOzqs4doyJ58P3ps9DFP8Hp2tAvbhP9%2B5z8VPL%2B%2BNpEVDVN9U4EIJ5e',
      },
    },
  },
};
