import { Injectable } from '@angular/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import * as Sentry from '@sentry/angular-ivy';
import { Platform } from '@ionic/angular';

const firebasePushKeys: Record<string, string> = {
  landing_page_myTransfers: 'my-transfers',
  landing_page_allTransfers: 'transfers',
  landing_page_reporting: 'reports',
  landing_page_academy: 'trainings',
  landing_page_personalDetails: 'profile',
  landing_page_terms: 'agb',
};

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  public pushNotificationRedirectionRoute: string | undefined;

  constructor(
    private router: Router,
    private authService: AuthService,
    private platform: Platform,
  ) {}

  initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush();
    }
  }

  private async registerPush() {
    try {
      await this.addListeners();

      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  addListeners() {
    PushNotifications.addListener('registration', async (token: Token) => {
      console.log('My token', token);
    });

    PushNotifications.addListener('registrationError', (err) => {
      Sentry.captureException(err);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification) => {
        if (this.platform.is('android')) {
          await PushNotifications.getDeliveredNotifications().then((notifications) => {
            PushNotifications.removeDeliveredNotifications(notifications);
          });

          this.handleForegroundNotifications(notification);
        }
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        const key = notification.notification.data.key;

        this.navigateToPage(key);
      },
    );

    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (notification) => {
        const key = notification.notification.extra.key;

        this.navigateToPage(key);
      },
    );
  }

  private async handleForegroundNotifications(notification: any) {
    await LocalNotifications.schedule({
      notifications: [
        {
          title: notification.title as string,
          body: notification.body as string,
          id: 1,
          schedule: { at: new Date(Date.now() + 1000) },
          extra: notification.data,
        },
      ],
    });
  }

  private navigateToPage(key: string) {
    if (!key) {
      return;
    }

    let route;

    if (firebasePushKeys.hasOwnProperty(key)) {
      route = firebasePushKeys[key];
    }

    this.pushNotificationRedirectionRoute = route;

    if (!this.authService.isUserLoggedIn) {
      this.router.navigate(['login']);
    }

    if (route && this.authService.isUserLoggedIn) {
      this.router.navigate([route]);
    }
  }
}
