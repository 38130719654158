import {
  BillingStatus,
  CustomLocation,
  CustomTransferStatus,
  PlanningStatus,
  Tour,
  WarningStatus,
} from '../../tours/model/tour.model';
import { Transfer } from '../../transfers/model/transfer.model';
import { environment } from '../../../environments/environment';

export class MyPlannedTransfer {
  constructor(
    public id: string,
    public transfer: Transfer,
    public tour: Tour,
    public planningStatus: PlanningStatus,
    public billingInfo: BillingInfo,
    public resolveToStatus: PlanningStatus | null,
    public plannedPickupDate: string,
    public receipts: Receipt[],
    public pickupDocumentationInfo: DocumentationInfo,
    public registration: string,
    public warningStatus?: WarningStatus,
  ) {}
}

export class MyCustomTransfer {
  constructor(
    public id: string,
    public origin: CustomLocation,
    public destination: CustomLocation,
    public plannedPickupDate: string,
    public customTransferStatus: CustomTransferStatus,
    public description: string,
    public amountMinorUnits: number,
  ) {}
}

export class MyTransfers {
  constructor(
    public plannedTransfers: MyPlannedTransfer[],
    public customTransfers: MyCustomTransfer[],
  ) {}
}

export class DocumentationInfo {
  constructor(
    public registration: string,
    public mileage: string,
    public timestamp: string,
  ) {}
}

export class BillingInfo {
  constructor(
    public status: BillingStatus,
    public invoiceNumber?: string,
  ) {}
}

export function isMyPlannedTransfer(
  plannedOrCustom: MyCustomTransfer | MyPlannedTransfer,
) {
  return 'transfer' in plannedOrCustom;
}

export enum ReceiptType {
  FuelReceipt = 'fuel_receipt',
  AdBlue = 'ad_blue',
  Customs = 'customs',
  WaitingTime = 'waiting_time',
  Other = 'other',
}

export class Receipt {
  constructor(
    public type: ReceiptType,
    public amountMinorUnits: number,
    public imgUrl: string,
    public description: string | undefined,
    public id?: string,
  ) {}
}

export enum Steps {
  Pickup = 'pickup',
  Dropoff = 'dropoff',
  Completion = 'completion',
}

export function getImageUrl(receipt: Receipt): string {
  return `${environment.carmovaApiUrl}/planned-transfer-receipts/${receipt.id}/url`;
}
