import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

type Headers =
  | HttpHeaders
  | {
      [header: string]: string | string[];
    };

type Options = {
  headers?: Headers;
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedHttpClient {
  constructor(
    private http: HttpClient, private authService: AuthService
  ) {}

  get<T>(url: string, options?: Options): Observable<T> {
    return from(this.authService.getToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw Error('Unauthorized');
        }

        return this.http.get<T>(url, {
          ...options,
          headers: this.getHeadersWithAuthorization(options?.headers, token),
        });
      }),
    );
  }

  post<T>(url: string, body: any | null, options?: Options): Observable<T> {
    return this.putOrPost('post', url, body, options);
  }

  put<T>(url: string, body: any | null, options?: Options): Observable<T> {
    return this.putOrPost('put', url, body, options);
  }

  delete<T>(url: string, options?: Options): Observable<T> {
    return from(this.authService.getToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw Error('Unauthorized');
        }

        return this.http.delete<T>(url, {
          ...options,
          headers: this.getHeadersWithAuthorization(options?.headers, token),
        });
      }),
    );
  }

  private putOrPost<T>(
    method: 'put' | 'post',
    url: string,
    body: any | null,
    options?: Options,
  ): Observable<T> {
    return from(this.authService.getToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw Error('Unauthorized');
        }

        return method === 'put'
          ? this.http.put<T>(url, body, {
              ...options,
              headers: this.getHeadersWithAuthorization(
                options?.headers,
                token,
              ),
            })
          : this.http.post<T>(url, body, {
              ...options,
              headers: this.getHeadersWithAuthorization(
                options?.headers,
                token,
              ),
            });
      }),
    );
  }

  private getHeadersWithAuthorization(
    headers: Headers | undefined,
    token: string,
  ): Headers {
    if (headers instanceof HttpHeaders) {
      headers.set('Authorization', 'Bearer ' + token);
    } else if (headers) {
      headers['Authorization'] = 'Bearer ' + token;
    } else {
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }

    return headers;
  }
}
