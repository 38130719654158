import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LanguagesService } from '../../services/languages.service';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private languagesService: LanguagesService) {}

  transform(value: any, pattern: string): any {
    const datePipe: DatePipe = new DatePipe(
      this.languagesService.activeLanguage.key,
    );
    return datePipe.transform(value, pattern);
  }
}
