import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';

export class MovacarProMinimumBuild {
  constructor(
    public android: number,
    public ios: number,
  ) {}
}

export class MovacarProStoreLink {
  private readonly _platform: string;

  constructor(platform) {
    this._platform = platform;
  }

  public href() {
    return this.getProductionLink();
  }

  private getProductionLink() {
    if (this._platform === 'android') {
      return 'https://play.google.com/store/apps/details?id=com.eindesein.movacar.pro';
    }

    return 'https://apps.apple.com/app/com.eindesein.movacar.pro/6467383951';
  }
}

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private apiUrl: string = `${environment.carmovaApiUrl}/version`;
  private _appInfo: AppInfo | null;
  private _minimumBuild: MovacarProMinimumBuild;
  private readonly _platform: string;
  private readonly _isProduction: boolean;

  private updateRequired$: Subject<boolean> = new Subject();

  constructor(private http: HttpClient) {
    this._isProduction = environment.production;
    this._platform = Capacitor.getPlatform();

    // noinspection JSIgnoredPromiseFromCall
    this.loadAppInfo();
  }

  get appInfo(): AppInfo | null {
    return this._appInfo;
  }

  get minimumBuild(): MovacarProMinimumBuild {
    return this._minimumBuild;
  }

  get platform(): string {
    return this._platform;
  }

  get isProduction(): boolean {
    return this._isProduction;
  }

  get currentBuild(): number {
    if (!this.appInfo) {
      return 0;
    }

    return parseInt(this.appInfo.build);
  }

  get updateRequired(): Subject<boolean> {
    return this.updateRequired$;
  }

  async init() {
    await this.loadAppInfo();

    return this;
  }

  async loadAppInfo(): Promise<void> {
    if (!Capacitor.isNativePlatform()) {
      this._appInfo = null;

      return;
    }

    this._appInfo = await App.getInfo();
  }

  async checkMinimumBuild() {
    const endpoint: string = `${this.apiUrl}/builds/minimum`;

    await this.loadAppInfo();

    if (!this.platform) {
      return;
    }

    if (this.platform === 'web') {
      return;
    }

    if (!this.appInfo) {
      return;
    }

    if (!this.currentBuild) {
      return;
    }

    this.http.get<MovacarProMinimumBuild>(endpoint).subscribe({
      next: (minimum: MovacarProMinimumBuild): void => {
        this._minimumBuild = minimum;

        if (typeof this.minimumBuild[this.platform] !== 'number') {
          this.updateRequired$.next(false);

          return;
        }

        if (this.minimumBuild[this.platform] > this.currentBuild) {
          this.updateRequired$.next(true);

          return;
        }
      },
      error: (error: HttpErrorResponse): void => {
        Sentry.captureException(error);
        throwError(
          () => new Error('movacarpro_version_service_check_minimum_build'),
        );
      },
    });
  }

  getStoreLink(): string {
    return new MovacarProStoreLink(this.platform).href();
  }
}
