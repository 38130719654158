import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard {
  constructor(private _auth: AuthService, private _router: Router) {}

  canActivate(): Observable<boolean> {
    return this._auth.isLoggedIn().pipe(
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          this._router.navigate(['/login']);
        }
      }),
    );
  }
}
