<ion-modal
  [isOpen]="isOpen"
  can-dismiss="false"
>
  <ng-template>
    <ion-content forceOverscroll="false">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col class="ion-text-center">
            <ion-img class="app-unboxing-icon" src="assets/movacar-update-delivery-box.png"></ion-img>
            <ion-text class="ion-text-center">
              <h1>{{ 'movacarpro_forbidden_browser_modal_title' | translate }}</h1>
              <p>{{ 'movacarpro_forbidden_browser_modal_body' | translate }}</p>
            </ion-text>
            <ion-button (click)="goToStore()">{{ 'movacarpro_forbidden_browser_modal_cta' | translate }}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ng-template>
</ion-modal>
