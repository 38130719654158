import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  from,
  map,
  mergeMap,
  Observable,
  tap,
  throwError,
} from 'rxjs';
import { AuthenticatedHttpClient } from 'src/app/auth/authenticatedHttpClient';
import { OfflineService } from 'src/app/services/offline.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../services/storage.service';
import {
  PlannedTransfer,
  PlanningStatus,
  WarningStatus,
} from '../../tours/model/tour.model';
import {
  MyCustomTransfer,
  MyPlannedTransfer,
  MyTransfers,
  ReceiptType,
} from '../model/my-transfer.model';

const myTransfersKey: string = 'my_transfers';
const storedAtKey: string = 'my_transfers.stored_at';
export enum MyTransfersSource {
  Api = 'api',
  Storage = 'storage',
}

@Injectable({
  providedIn: 'root',
})
export class MyTransfersService {
  public myTransfer: MyPlannedTransfer;

  private plannedApiUrl: string = `${environment.carmovaApiUrl}/planned-transfers`;
  private customApiUrl: string = `${environment.carmovaApiUrl}/custom-transfers`;
  private _transfers$: BehaviorSubject<MyTransfers | null> =
    new BehaviorSubject<MyTransfers | null>(null);

  constructor(
    private readonly http: AuthenticatedHttpClient,
    private storageService: StorageService,
  ) {}

  get transfers$(): BehaviorSubject<MyTransfers | null> {
    return this._transfers$;
  }

  fetch(source: MyTransfersSource = MyTransfersSource.Api) {
    if (source === MyTransfersSource.Storage) {
      return this.getAllFromStorage();
    }

    return this.getAllFromApi();
  }

  async storeTransfers(myTransfers: MyTransfers): Promise<void> {
    await this.storageService.set(myTransfersKey, myTransfers);
    await this.storageService.set(storedAtKey, new Date().toISOString());
    this._transfers$.next(myTransfers);
  }

  getAllFromApi(): Observable<MyTransfers | null> {
    return this.http
      .get<{ plannedTransfers: MyPlannedTransfer[] }>(this.plannedApiUrl)
      .pipe(
        map((response) => response.plannedTransfers),
        mergeMap((plannedTransfers: MyPlannedTransfer[]) => {
          return this.http
            .get<{ customTransfers: MyCustomTransfer[] }>(this.customApiUrl)
            .pipe(
              map(({ customTransfers }): MyTransfers => {
                return { plannedTransfers, customTransfers };
              }),
            );
        }),
        // todo: maybe this code below should be moved to fetch()
        tap(async (myTransfers: MyTransfers): Promise<void> => {
          await this.storeTransfers(myTransfers);
        }),
        catchError(
          async (error: HttpErrorResponse): Promise<MyTransfers | null> => {
            Sentry.captureException(error);

            const storedMyTransfers: MyTransfers =
              await this.storageService.get(myTransfersKey);

            this._transfers$.next(storedMyTransfers);

            if (storedMyTransfers === null) {
              throwError(
                () => new Error('movacarpro_error_load_transfers_failed'),
              );
            }

            return storedMyTransfers;
          },
        ),
      );
  }

  getAllFromStorage(): Observable<MyTransfers> {
    return from(
      (async (): Promise<MyTransfers> => {
        const myTransfers: MyTransfers | null =
          await this.storageService.get(myTransfersKey);

        if (myTransfers === null) {
          throw new Error('movacarpro_my_transfers_service_storage_empty');
        }

        return myTransfers;
      })(),
    );
  }

  acceptPlannedTransfer(
    transferId: string,
    plannedPickupDate: string,
  ): Observable<MyPlannedTransfer> {
    let url = `${this.plannedApiUrl}/${transferId}/accept`;
    return this.http
      .post<MyPlannedTransfer>(url, {
        plannedPickupDate: plannedPickupDate,
      })
      .pipe(
        tap(this.updateTransfersSubject()),
        catchError((error: HttpErrorResponse) => {
          Sentry.captureException(error);

          return throwError(
            () => new Error('movacarpro_error_message_unknown'),
          );
        }),
      );
  }

  acceptCustomTransfer(
    transferId: string,
    plannedPickupDate: string,
  ): Observable<MyCustomTransfer> {
    let url: string = `${this.customApiUrl}/${transferId}/accept`;
    return this.http
      .post<MyCustomTransfer>(url, {
        plannedPickupDate: plannedPickupDate,
      })
      .pipe(
        tap(async (acceptedTransfer: MyCustomTransfer): Promise<void> => {
          const currentTransfers: MyTransfers | null = this.transfers$.value;

          if (currentTransfers === null) {
            return;
          }

          currentTransfers.customTransfers.map(
            (myTransfer: MyCustomTransfer): MyCustomTransfer => {
              return myTransfer.id === acceptedTransfer.id
                ? acceptedTransfer
                : myTransfer;
            },
          );

          await this.storeTransfers(currentTransfers);
        }),
        catchError((error: HttpErrorResponse) => {
          Sentry.captureException(error);

          return throwError(
            () => new Error('movacarpro_error_message_unknown'),
          );
        }),
      );
  }

  getMyTransferById(myTransferId: string): Observable<MyPlannedTransfer> {
    return this.getAllFromStorage().pipe(
      map((myTransfers: MyTransfers) => {
        const foundTransfer: MyPlannedTransfer | undefined =
          myTransfers.plannedTransfers.find(
            (plannedTransfer: MyPlannedTransfer): boolean =>
              plannedTransfer.id === myTransferId,
          );

        if (!foundTransfer) {
          throw new Error('movacarpro_my_transfers_service_transfer_not_found');
        }

        return foundTransfer;
      }),

      tap((myTransfer: MyPlannedTransfer) => {
        this.myTransfer = myTransfer;
      }),
    );
  }

  async getMyTransferByIdAsPromise(
    myTransferId: string,
  ): Promise<MyPlannedTransfer> {
    return firstValueFrom(this.getMyTransferById(myTransferId));
  }

  reportProblem(myTransferId: string, problemType: WarningStatus) {
    return this.http
      .post<MyPlannedTransfer>(
        `${this.plannedApiUrl}/${myTransferId}/report-problem`,
        {
          type: problemType,
        },
      )
      .pipe(
        tap(this.updateTransfersSubject()),
        catchError((error: HttpErrorResponse) => {
          Sentry.captureException(error);

          if (
            error.status === 400 &&
            error.error.message === 'Transfer is not assigned or started'
          ) {
            return throwError(
              () => new Error('movacarpro_error_report_problem_impossible'),
            );
          }
          return throwError(
            () => new Error('movacarpro_error_message_unknown'),
          );
        }),
      );
  }

  resolveProblem(myTransferId: string) {
    return this.http
      .post<MyPlannedTransfer>(
        `${this.plannedApiUrl}/${myTransferId}/resolve-problem`,
        {},
      )
      .pipe(
        tap(this.updateTransfersSubject()),
        catchError((error: HttpErrorResponse) => {
          Sentry.captureException(error);

          return throwError(
            () => new Error('movacarpro_error_message_unknown'),
          );
        }),
      );
  }

  completeDocumentation(transferId: string) {
    let url = `${this.plannedApiUrl}/${transferId}/documentation-done`;
    return this.http.post<MyPlannedTransfer>(url, {}).pipe(
      tap(this.updateTransfersSubject()),
      catchError((error: HttpErrorResponse) => {
        Sentry.captureException(error);

        return throwError(() => new Error('movacarpro_error_message_unknown'));
      }),
    );
  }

  addReceipt(
    transferId: string,
    type: ReceiptType,
    amountMinorUnits: number,
    imageFile: File,
    description: string | undefined,
  ) {
    const postData = new FormData();
    postData.append('file', imageFile);
    postData.append('type', type);
    postData.append('amountMinorUnits', amountMinorUnits.toString());

    if (type === ReceiptType.Other && description) {
      postData.append('description', description);
    }

    const url = `${this.plannedApiUrl}/${transferId}/receipts`;
    return this.http.post<MyPlannedTransfer>(url, postData).pipe(
      tap(this.updateTransfersSubject()),
      catchError((error: HttpErrorResponse) => {
        Sentry.captureException(error);

        return throwError(
          () => new Error('movacarpro_error_add_receipt_failed'),
        );
      }),
    );
  }

  updateReceipt(
    transferId: string,
    receiptId: string,
    type: ReceiptType,
    amountMinorUnits: number,
    imageFile: File | null,
    description: string | undefined,
  ) {
    const postData = new FormData();
    postData.append('type', type);
    postData.append('amountMinorUnits', amountMinorUnits.toString());
    if (imageFile) {
      postData.append('file', imageFile);
    }

    if (type === ReceiptType.Other && description) {
      postData.append('description', description);
    }

    const url = `${this.plannedApiUrl}/${transferId}/receipts/${receiptId}`;
    return this.http.put<MyPlannedTransfer>(url, postData).pipe(
      tap(this.updateTransfersSubject()),
      catchError((error: HttpErrorResponse) => {
        Sentry.captureException(error);

        return throwError(
          () => new Error('movacarpro_error_edit_receipt_failed'),
        );
      }),
    );
  }

  deleteReceipt(transferId: string, receiptId: string) {
    const url = `${this.plannedApiUrl}/${transferId}/receipts/${receiptId}`;
    return this.http.delete<MyPlannedTransfer>(url).pipe(
      tap(this.updateTransfersSubject()),
      catchError((error: HttpErrorResponse) => {
        Sentry.captureException(error);

        return throwError(
          () => new Error('movacarpro_error_delete_receipt_failed'),
        );
      }),
    );
  }

  private updateTransfersSubject() {
    return async (updatedTransfer: MyPlannedTransfer): Promise<void> => {
      const currentTransfers: MyTransfers | null = this._transfers$.value;

      if (currentTransfers === null) {
        return;
      }

      const plannedTransfers: MyPlannedTransfer[] =
        currentTransfers.plannedTransfers.map(
          (myTransfer: MyPlannedTransfer): MyPlannedTransfer => {
            return myTransfer.id === updatedTransfer.id
              ? updatedTransfer
              : myTransfer;
          },
        );

      await this.storeTransfers({
        plannedTransfers,
        customTransfers: currentTransfers.customTransfers,
      });
    };
  }

  public updateMyTrgnsferStatus(
    myTransferId: string,
    newStatus: PlanningStatus,
  ): Observable<MyPlannedTransfer> {
    return from(
      (async (): Promise<MyPlannedTransfer> => {
        const myTransfers = await this.storageService.get(myTransfersKey);

        const plannedTransferToBeUpdated: MyPlannedTransfer | null =
          myTransfers.plannedTransfers.find(
            (plannedTransfer: MyPlannedTransfer): boolean => {
              return plannedTransfer.id === myTransferId;
            },
          );

        if (!plannedTransferToBeUpdated) {
          throw Error('Failed to update planned transfer status');
        }

        plannedTransferToBeUpdated.planningStatus = newStatus;
        const updatedPlannedTransfer: MyPlannedTransfer =
          plannedTransferToBeUpdated;

        myTransfers.plannedTransfers.map(
          (plannedTransfer: MyPlannedTransfer): PlannedTransfer => {
            return plannedTransfer.id === updatedPlannedTransfer.id
              ? updatedPlannedTransfer
              : plannedTransfer;
          },
        );

        await this.storageService.set(myTransfersKey, myTransfers);

        return updatedPlannedTransfer;
      })(),
    );
  }
}
