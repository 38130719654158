import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { BrowserInfoServiceService } from '../../services/browser-info-service.service';

@Component({
  selector: 'app-forbidden-browser-modal',
  templateUrl: './forbidden-browser-modal.component.html',
  styleUrls: ['./forbidden-browser-modal.component.scss'],
})
export class ForbiddenBrowserModalComponent implements OnInit {
  isOpen: boolean = false;
  constructor(private readonly browserInfoService: BrowserInfoServiceService) {}

  ngOnInit(): void {
    const capacitorPlatform: string = Capacitor.getPlatform();

    if (capacitorPlatform !== 'web') {
      return;
    }

    if (this.browserInfoService.deviceType === 'desktop') {
      return;
    }

    this.isOpen = true;
  }

  goToStore(): void {
    this.browserInfoService.goToStore();
  }
}
