import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { AuthenticatedHttpClient } from '../../auth/authenticatedHttpClient';
import { Company } from '../model/company.model';
import * as Sentry from '@sentry/angular-ivy';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public company: Company;

  private apiUrl = `${environment.carmovaApiUrl}/accounts/profile`;

  private _company$: BehaviorSubject<Company | undefined> = new BehaviorSubject<
    Company | undefined
  >(undefined);

  constructor(private readonly http: AuthenticatedHttpClient) {}

  get company$(): BehaviorSubject<Company | undefined> {
    return this._company$;
  }

  fetchCompanyProfile() {
    return this.http.get<Company>(this.apiUrl).pipe(
      tap((company) => {
        this._company$.next(company);
        this.company = company;
      }),
      catchError((error: HttpErrorResponse) => {
        Sentry.captureException(error);

        return throwError(() => new Error('movacarpro_error_message_unknown'));
      }),
    );
  }

  updateCompanyProfile(companyUpdate: Company): Observable<Company> {
    return this.http
      .put<Company>(`${this.apiUrl}`, companyUpdate, {
        headers: { 'content-Type': 'application/json' },
      })
      .pipe(
        tap((updatedCompany: Company) => {
          this._company$.next(updatedCompany);
        }),
        catchError((error: HttpErrorResponse) => {
          Sentry.captureException(error);

          return throwError(
            () => new Error('movacarpro_error_saving_data_failed'),
          );
        }),
      );
  }
}
