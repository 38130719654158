import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  private readonly _languages: Language[] = [
    { key: 'de', value: 'Deutsch' },
    { key: 'en', value: 'English' },
  ];
  private _activeLanguage!: Language;

  constructor(private _translate: TranslateService) {
    this._activeLanguage =
      this._languages.find(
        (language) => language.key === this._translate.getDefaultLang(),
      ) || this.languages[0];
  }

  private _onLanguageChange = new Subject<Language>();
  onLanguageChange = this._onLanguageChange.asObservable();

  languageChange() {
    this._translate.use(this.activeLanguage.key);
    this._onLanguageChange.next(this.activeLanguage);
  }

  get languages(): Language[] {
    return this._languages;
  }
  get activeLanguage(): Language {
    return this._activeLanguage;
  }

  set activeLanguage(value: Language) {
    this._activeLanguage = value;
  }
}
export interface Language {
  key: string;
  value: string;
}
