import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import { LocalizedDatePipe } from './pipes/localized-date-pipe';
import { TransferStatusBadgeComponent } from './components/transfer-status-badge/transfer-status-badge.component';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '../app.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DriverInstructionsComponent } from './components/driver-instructions/driver-instructions.component';
import { OpeningHoursModalComponent } from './components/opening-hours-modal/opening-hours-modal.component';
import { StationDetailsComponent } from './components/station-details/station-details.component';
import { ReceiptDetailModalComponent } from './components/receipt-detail-modal/receipt-detail-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AddressInputModalComponent } from './components/address-input-modal/address-input-modal.component';
import { TransferWarningStatusBadgeComponent } from './components/transfer-warning-status-badge/transfer-warning-status-badge.component';
import { PdfModalComponent } from './components/pdf-modal/pdf-modal.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NetworkStateComponent } from './components/network-state/network-state.component';
import { UpdateRequestModalComponent } from './components/update-request-modal/update-request-modal.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ForbiddenBrowserModalComponent } from './components/forbidden-browser-modal/forbidden-browser-modal.component';
import { ObjectToKeysPipe } from './pipes/object-to-keys.pipe';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { MobileOnlyComponent } from './components/mobile-only/mobile-only.component';

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    LocalizedDatePipe,
    SafeUrlPipe,
    TransferStatusBadgeComponent,
    DriverInstructionsComponent,
    OpeningHoursModalComponent,
    StationDetailsComponent,
    ReceiptDetailModalComponent,
    AddressInputModalComponent,
    TransferWarningStatusBadgeComponent,
    PdfModalComponent,
    NetworkStateComponent,
    UpdateRequestModalComponent,
    ForbiddenBrowserModalComponent,
    ObjectToKeysPipe,
    SignaturePadComponent,
    MobileOnlyComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
  ],
  exports: [
    LocalizedDatePipe,
    SafeUrlPipe,
    TransferStatusBadgeComponent,
    DriverInstructionsComponent,
    OpeningHoursModalComponent,
    StationDetailsComponent,
    ReceiptDetailModalComponent,
    AddressInputModalComponent,
    TransferWarningStatusBadgeComponent,
    PdfModalComponent,
    NetworkStateComponent,
    UpdateRequestModalComponent,
    ForbiddenBrowserModalComponent,
    ObjectToKeysPipe,
    SignaturePadComponent,
    MobileOnlyComponent,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'de' }, LocalizedDatePipe],
})
export class SharedModule {}
