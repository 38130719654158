import { Injectable } from '@angular/core';
import Bowser from 'bowser';

@Injectable({
  providedIn: 'root',
})
export class BrowserInfoServiceService {
  private readonly parser: Bowser.Parser.Parser;
  private readonly _browser: Bowser.Parser.BrowserDetails;
  private readonly _platform: Bowser.Parser.PlatformDetails;

  private readonly appstoreLinks = {
    apple: 'https://apps.apple.com/app/com.eindesein.movacar.pro/6467383951',
    android:
      'https://play.google.com/store/apps/details?id=com.eindesein.movacar.pro',
  };

  constructor() {
    this.parser = Bowser.getParser(window.navigator.userAgent);
    this._browser = this.parser.getBrowser();
    this._platform = this.parser.getPlatform();
  }

  get name() {
    return this._browser.name;
  }

  get deviceType() {
    return this._platform.type;
  }

  get deviceBrand() {
    const platform = this._platform.vendor || 'android';

    return platform.toLowerCase();
  }

  goToStore() {
    window.open(this.appstoreLinks[this.deviceBrand], '_blank');
  }
}
