import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage;

  constructor(private storage: Storage) {
    this.init();
  }

  private async init(): Promise<void> {
    this._storage = await this.storage.create();
  }

  async set(key: string, value: any): Promise<any> {
    await this.ensureConnection();

    return await this._storage.set(key, value);
  }

  async get(key: string): Promise<any> {
    await this.ensureConnection();

    return await this._storage.get(key);
  }

  async delete(key: string): Promise<any> {
    await this.ensureConnection();

    return await this._storage.remove(key);
  }

  private async ensureConnection() {
    if (this._storage !== undefined) {
      return;
    }

    setTimeout(() => {
      console.log('Starting Local Storage');
    }, 1000);
  }
}
