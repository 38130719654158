import { Component, OnInit } from '@angular/core';
import { VersionService } from '../../../services/version.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-update-request-modal',
  templateUrl: './update-request-modal.component.html',
  styleUrls: ['./update-request-modal.component.scss'],
})
export class UpdateRequestModalComponent implements OnInit {
  private _isOpen: boolean = false;

  constructor(private readonly versionService: VersionService) {}

  async ngOnInit(): Promise<void> {
    this.versionService.updateRequired.subscribe({
      next: (presentModal: boolean): void => {
        if (Capacitor.isNativePlatform()) {
          this._isOpen = presentModal;
        }
      },
    });

    await this.versionService.checkMinimumBuild();
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  goToStore() {
    const storeLink: string = this.versionService.getStoreLink();

    if (storeLink === '') {
      return;
    }

    window.open(storeLink, '_system');
  }
}
