import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs/operators';

// Init for the web
import '@capacitor-community/firebase-analytics';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService {
  constructor(private router: Router) {}

  async initializeFirebase() {
    if (Capacitor.getPlatform() === 'web') {
      await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }

  async setScreenName(screenName: string) {
    await FirebaseAnalytics.setScreenName({
      screenName,
    });
  }

  async attachToRouter() {
    this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe(async (event: Event) => {
        const e: NavigationEnd = event as NavigationEnd;
        await this.setScreenName(e.url);
      });

    await FirebaseAnalytics.setCollectionEnabled({ enabled: true });
  }
}
